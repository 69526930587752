import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkToken } from "./../app/services/checkToken"; // Импортируем функцию

export const useTokenValidation = () => {
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        try {
          const response = await checkToken(token);
          if (response.isValidToken) {
            // Предполагается, что сервер возвращает isValidToken
            setIsValid(true);
          } else {
            navigate("/");
          }
        } catch (error) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    };

    validateToken();
  }, [navigate]);

  return isValid;
};
