import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils"

export const archiveListApi = createApi({
  reducerPath: "archiveList",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      const token = getTokenFromLocalStorage()
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["Archives"],
  endpoints: build => ({
    getArchiveList: build.query({
      query: date => "main/getArchiveGoods",
      providesTags: ["Archives"],
    }),
  }),
})

export const { useGetArchiveListQuery } = archiveListApi
