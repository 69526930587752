import React, { useEffect } from "react";
import { useCheckAuthKaspiMutation } from "../app/services/authApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../app/store";

export const KaspiTokenData = () => {
  const [checkAuthKaspi, { data, error, isLoading }] =
    useCheckAuthKaspiMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchKaspiData = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
          console.error("Ошибка при получении токена");
          return;
        }

        const response = await checkAuthKaspi({
          login: "sks_techno@mail.ru",
          password: "Password1",
          accessToken,
          isValidToken: true,
        });
      } catch (error) {
        console.error("Error fetching Kaspi data: ", error);
      }
    };

    fetchKaspiData();
  }, [checkAuthKaspi, dispatch]);

  const kaspiUserId = useSelector((state: RootState) => state.auth.kaspiUserId);
  const kaspiStoreName = useSelector(
    (state: RootState) => state.auth.kaspiStoreName
  );

  if (isLoading) return <p></p>;

  return (
    <div>
      <p>{kaspiStoreName}</p>
      <p>{kaspiUserId || "No data"}</p>
    </div>
  );
};
