import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "./../app/services/authApi"; // Импорт API
import type { IAuthResponse } from "./../app/types";

interface AuthState {
  user: IAuthResponse | null;
  token: string | null;
  isAuthenticated: boolean;
  kaspiUserId: string | null;
  kaspiStoreName: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  kaspiUserId: null,
  kaspiStoreName: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.kaspiUserId = null;
      state.kaspiStoreName = null;
      localStorage.removeItem("accessToken"); 
    },
    setCredentials: (
      state,
      action: PayloadAction<{ user: IAuthResponse; token: string }>
    ) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", action.payload.token); 
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.checkAuth.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.token = payload.accessToken;
        state.isAuthenticated = payload.isValid;
        localStorage.setItem("accessToken", payload.accessToken); 
      }
    );
    
    builder.addMatcher(
      authApi.endpoints.checkAuthKaspi.matchFulfilled,
      (state, { payload }) => {
        if (payload.result) {
          state.kaspiUserId = payload.result.kaspiUserId ?? null;
          state.kaspiStoreName = payload.result.kaspiStoreName ?? null;
        } else {
          state.kaspiUserId = null;
          state.kaspiStoreName = null;
        }
      }
    );
  },
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
