import axios from "axios";
import { BASE_URL } from "./../../constants";

export const checkToken = async (accessToken: string) => {
  const response = await axios.post(`${BASE_URL}auth/checkValidateTokenAsync`, accessToken, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
