import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils";

export const allDownloadKaspiGoodsApi = createApi({
  reducerPath: "allDownloadKaspiGoodsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["allDownloadKaspiGoodsApi"],
  endpoints: (build) => ({
    getAllDownloadKaspiGoodsApi: build.query({
      query: (date) => "main/allDownloadKaspiGoods",
      providesTags: ["allDownloadKaspiGoodsApi"],
    }),
  }),
});

export const {
  useGetAllDownloadKaspiGoodsApiQuery,
  useLazyGetAllDownloadKaspiGoodsApiQuery,
} = allDownloadKaspiGoodsApi;
