import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "ru",
  lng: "ru",
  resources: {
    ru: {
      translations: require("./locales/ru/translations.json"),
    },
    kz: {
      translations: require("./locales/kz/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["ru", "kz"];

export default i18n;
