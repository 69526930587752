import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MENU = [
  {
    titleKey: "page.main",
    url: "/main",
  },
  {
    titleKey: "page.goods",
    url: "/goods",
  },
  {
    titleKey: "page.history",
    url: "/history",
  },
  {
    titleKey: "page.requestsReturns",
    url: "/requestsReturns",
  },
];

export const Nav = () => {
  const { t } = useTranslation();

  return (
    <nav className="w-full flex justify-center">
      <ul className="flex flex-row gap-2">
        {MENU.map((item) => (
          <li key={item.url}>
            <Button
              color="primary"
              className="transition duration-300 ease-in-out transform hover:-translate-y-0.1 hover:scale-10"
            >
              <Link to={item.url}>{t(item.titleKey)}</Link>
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

{
  /* <NavButton href="/main-page" icon={<BsPSquareFill />}>
Главная страница
</NavButton> */
}
