import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils";

export type ITestRequestReturnsResponse = {
  isSuccess: boolean;
  result: {
    pickupCount: number;
    kaspiDeliveryCargoAssemblyCount: number;
    kaspiDeliveryWaitForCourierCount: number;
    kaspiDeliveryTransmittedCount: number;
    kaspiDeliveryReturnRequestCount: number;
  };
  displayMessage: string | null;
  errorMessages: string[] | null;
  isValidToken: boolean;
};

export type ITestRefundsOrdersCount = {
  isSuccess: boolean;
  result: {
    newCount: number;
    onDeliveryCount: number;
    waitingDecisionCount: number;
    disputeCount: number;
    closedCount: number;
  };
  displayMessage: string | null;
  errorMessages: string[] | null;
  isValidToken: boolean;
};

export const testRequestReturnsApi = createApi({
  reducerPath: "testRequestReturnsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["testRequestReturnsApi"],
  endpoints: (build) => ({
    getTestActiveOrdersCount: build.query<ITestRequestReturnsResponse, void>({
      query: () => "/main/getActiveOrdersCount",
      providesTags: ["testRequestReturnsApi"],
    }),
    getTestRefundsOrdersCount: build.query<ITestRefundsOrdersCount, void>({
      query: () => "/main/getRefundsOrdersCount",
      providesTags: ["testRequestReturnsApi"],
    }),
  }),
});

export const {
  useGetTestActiveOrdersCountQuery,
  useGetTestRefundsOrdersCountQuery
} = testRequestReturnsApi;
