import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils"

export const requestReturnsApi = createApi({
  reducerPath: "requestReturnsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      const token = getTokenFromLocalStorage()
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["requestReturnsApi"],
  endpoints: build => ({
    getAllStatData: build.query({
      query: () => "main/getAllStatData",
      providesTags: ["requestReturnsApi"],
    }),
    getActiveOrdersCount: build.query({
      query: () => "/main/getActiveOrdersCount",
      providesTags: ["requestReturnsApi"],
    }),
    getActiveOrdersCountParams: build.query({
      query: (orderKey) => `/main/getActiveOrdersCount/${orderKey}`,
      providesTags: ["requestReturnsApi"],
    }),
    getRefundsOrdersCount: build.query({
      query: () => "main/getRefundsOrdersCount",
      providesTags: ["requestReturnsApi"],
    }),
    getStatDataForRefundsOrders: build.query({
      query: (refundKey) => `main/getStatDataForRefundsOrders/${refundKey}`,
      providesTags: ["requestReturnsApi"],
    }),
  }),
})

export const {
  useGetAllStatDataQuery,
  useGetActiveOrdersCountQuery,
  useGetActiveOrdersCountParamsQuery,
  useGetRefundsOrdersCountQuery,
  useGetStatDataForRefundsOrdersQuery,
} = requestReturnsApi
