import React from "react";
import { ThemeContext } from "./ThemeProvider";
import { useContext } from "react";
import { Switch } from "@nextui-org/react";
import { FaRegMoon } from "react-icons/fa";
import { LuSunMedium } from "react-icons/lu";

export const ChangeTheme = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Switch
      onClick={toggleTheme}
      defaultSelected
      size="lg"
      color="success"
      thumbIcon={({ className }) =>
        theme === "light" ? (
          <LuSunMedium className={className} color="orange" />
        ) : (
          <FaRegMoon className={className} />
        )
      }
    ></Switch>
  );
};
