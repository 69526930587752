import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Spinner,
} from "@nextui-org/react";
import {
  useGetActiveOrdersCountParamsQuery,
  useGetAllStatDataQuery,
  useGetStatDataForRefundsOrdersQuery,
} from "./../app/services/requestReturnsApi";

import {
  MdOutlineMonetizationOn,
  MdOutlineRemoveShoppingCart,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { FaReceipt } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { HiMiniReceiptRefund } from "react-icons/hi2";
import { TbReceiptTax } from "react-icons/tb";
import {
  useGetTestActiveOrdersCountQuery,
  useGetTestRefundsOrdersCountQuery,
} from "./../app/services/testRequestReturnsApi";
import { useTranslation } from "react-i18next";

export const RequestsReturns = () => {
  const { t } = useTranslation();
  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [refundKey, setRefundKey] = useState<string | null>(null);

  const { data: allStatData, isLoading: isLoadingAllStat } =
    useGetAllStatDataQuery(0);
  const allStat = allStatData?.result || [];

  const { data: ordersData, isLoading: isLoadingOrders } =
    useGetActiveOrdersCountParamsQuery(orderKey || "");
  const orders = ordersData?.result || [];

  const { data: refundsData, isLoading: isLoadingRefunds } =
    useGetStatDataForRefundsOrdersQuery(refundKey, {
      skip: !refundKey,
    });

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "KZT",
    }).format(value);
  };
  const refunds = refundsData?.result || [];

  const handleOrderClick = (key: string) => {
    setOrderKey(key);
    setRefundKey(null);
  };

  const handleRefundClick = (key: string) => {
    setRefundKey(key);
    setOrderKey(null);
  };

  const { data: orderDataCount, isLoading: isLoadingTestCount } =
    useGetTestActiveOrdersCountQuery();
  const { data: orderDataReturn, isLoading: isLoadingTestReturn } =
    useGetTestRefundsOrdersCountQuery();

  if (
    isLoadingOrders ||
    isLoadingRefunds ||
    isLoadingAllStat ||
    isLoadingTestCount ||
    isLoadingTestReturn
  )
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );

  const stats = orderKey ? orders : refundKey ? refunds : allStat;

  return (
    <section className="w-full px-5">
      <Card className="gap-5 mt-5 p-5">
        <p className="text-2xl">{t("page.requestsReturns")}</p>
      </Card>
      <div>
        {stats ? (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mt-5">
            <Card className="p-5 flex-row items-center flex-1">
              <FcSalesPerformance size={60} />
              <CardBody>
                <div className="flex flex-col">
                  <p className="text-small text-default-500">
                    {t("text.sales")}
                  </p>
                  <div>{formatCurrency(stats.salesAmount)}</div>
                </div>
              </CardBody>
            </Card>
            <Card className="p-5 flex-row items-center flex-1">
              <TbReceiptTax size={60} />
              <CardBody>
                <div className="flex flex-col">
                  <p className="text-small text-default-500">
                    {t("text.commission")}
                  </p>
                  <div>{stats.amountPercentageСommission}%</div>
                </div>
              </CardBody>
            </Card>
            <Card className="p-5 flex-row items-center flex-1">
              <MdOutlineMonetizationOn size={65} color="gold" />
              <CardBody>
                <div className="flex flex-col">
                  <p className="text-small text-default-500">
                    {t("text.netProfit")}
                  </p>
                  <div>{formatCurrency(stats.amountOfNetIncome)}</div>
                </div>
              </CardBody>
            </Card>
            <Card className="p-5 flex-row items-center flex-1">
              <FaReceipt size={45} />
              <CardBody>
                <div className="flex flex-col">
                  <p className="text-small text-default-500">
                    {t("text.averageCheck")}
                  </p>
                  <div>{formatCurrency(stats.averageReceipt)}</div>
                </div>
              </CardBody>
            </Card>
            <Card className="p-5 flex-row items-center flex-1">
              <HiMiniReceiptRefund size={70} />
              <CardBody>
                <div className="flex flex-col">
                  <p className="text-small text-default-500">
                    {t("text.returns")}
                  </p>
                  <div>{formatCurrency(stats.amountOfRefunds)}</div>
                </div>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>{t("errors.error")}</div>
        )}
      </div>
      <Card className="mt-5 p-5 flex  items-end">
        <Button
          className="max-w-32"
          color="primary"
          onClick={() => {
            setOrderKey(null);
            setRefundKey(null);
          }}
        >
          {t("button.reset")}
        </Button>
      </Card>
      <div className="grid grid-cols-2 my-5 gap-5">
        <Card className="p-5">
          <CardHeader>
            <MdOutlineShoppingCart size={30} />
            <div className="flex flex-col ml-2">
              <p className="text-xl">{t("text.orders")}</p>
            </div>
          </CardHeader>
          <Divider />

          <CardBody>
            {orders ? (
              <div key={orders.id} className="grid gap-5">
                <Button
                  className="flex justify-between w-full mt-3"
                  onClick={() => handleOrderClick("PICKUP")}
                >
                  <p className="text-lg">{t("text.pickup")}:</p>
                  <p className="text-lg">
                    {orderDataCount?.result.pickupCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() =>
                    handleOrderClick("KASPI_DELIVERY_CARGO_ASSEMBLY")
                  }
                >
                  <p className="text-lg">{t("text.packaging")}</p>
                  <p className="text-lg">
                    {orderDataCount?.result.kaspiDeliveryCargoAssemblyCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() =>
                    handleOrderClick("KASPI_DELIVERY_WAIT_FOR_COURIER")
                  }
                >
                  <p className="text-lg">{t("text.transfer")}</p>
                  <p className="text-lg">
                    {orderDataCount?.result.kaspiDeliveryWaitForCourierCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() => handleOrderClick("KASPI_DELIVERY_TRANSMITTED")}
                >
                  <p className="text-lg">{t("text.transferForDelivery")}</p>
                  <p className="text-lg">
                    {orderDataCount?.result.kaspiDeliveryTransmittedCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() =>
                    handleOrderClick("KASPI_DELIVERY_RETURN_REQUEST")
                  }
                >
                  <p className="text-lg">{t("text.cancelledOnDelivery")}</p>
                  <p className="text-lg">
                    {orderDataCount?.result.kaspiDeliveryReturnRequestCount}
                  </p>
                </Button>
              </div>
            ) : (
              <p className="text-lg">{t("errors.ordersError")}</p>
            )}
          </CardBody>
        </Card>
        <Card className="p-5">
          <CardHeader>
            <MdOutlineRemoveShoppingCart size={30} />
            <div className="flex flex-col ml-2">
              <p className="text-xl">{t("text.returns")}</p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            {refunds ? (
              <div key={refunds.id} className="grid gap-5">
                <Button
                  className="flex justify-between w-full mt-3"
                  onClick={() => handleRefundClick("NEW")}
                >
                  <p className="text-lg">{t("text.new")}:</p>
                  <p className="text-lg">{orderDataReturn?.result.newCount}</p>
                </Button>
                <Button
                  className="flex justify-between w-full "
                  onClick={() => handleRefundClick("ON_DELIVERY")}
                >
                  <p className="text-lg">{t("text.onDelivery")}:</p>
                  <p className="text-lg">
                    {orderDataReturn?.result.onDeliveryCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() => handleRefundClick("WAITING_DECISION")}
                >
                  <p className="text-lg">{t("text.awaitingDecision")}:</p>
                  <p className="text-lg">
                    {orderDataReturn?.result.waitingDecisionCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() => handleRefundClick("DISPUTE")}
                >
                  <p className="text-lg">{t("text.controversies")}:</p>
                  <p className="text-lg">
                    {orderDataReturn?.result.disputeCount}
                  </p>
                </Button>
                <Button
                  className="flex justify-between w-full"
                  onClick={() => handleRefundClick("CLOSED")}
                >
                  <p className="text-lg">{t("text.closedApplications")}:</p>
                  <p className="text-lg">
                    {orderDataReturn?.result.closedCount}
                  </p>
                </Button>
              </div>
            ) : (
              <p className="text-lg">{t("errors.returnsError")}</p>
            )}
          </CardBody>
        </Card>
      </div>
    </section>
  );
};
