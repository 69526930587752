import { useParams, useNavigate } from "react-router-dom";
import { useTokenValidation } from "../utils/tokenValidation";
import {
  useGetGoodsQuery,
  useUpdateGoodMutation,
} from "./../app/services/goodsApi";
import { Button, Card, Image, Input, Spinner } from "@nextui-org/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CurrentGoods = () => {
  const isValid = useTokenValidation();
  const { t } = useTranslation();
  const { masterSku } = useParams<{ masterSku: string }>();
  const { data, isLoading } = useGetGoodsQuery(null);
  const navigate = useNavigate();
  const [updateGood] = useUpdateGoodMutation();

  const [formData, setFormData] = useState({
    imageLink: "",
    masterTitle: "",
    masterSku: "",
    articul: "",
    model: "",
    verticalCategory: "",
    priceNow: 0,
    costOfGoods: 0,
    сommissionRate: 0,
    shippingCost: 0,
    netProfit: 0,
    profit: 0,
  });

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "KZT",
    }).format(value);
  };

  const item = data?.result.find((good: any) => good.masterSku === masterSku);

  if (item && formData.masterSku === "") {
    setFormData({
      imageLink: item.imageLink || "",
      masterTitle: item.masterTitle || "",
      masterSku: item.masterSku || "",
      articul: item.articul || "",
      model: item.model || "",
      verticalCategory: item.verticalCategory || "",
      priceNow: item.priceNow || 0,
      costOfGoods: item.costOfGoods || 0,
      сommissionRate: item.сommissionRate || 0,
      shippingCost: item.shippingCost || 0,
      netProfit: item.netProfit || 0,
      profit: item.profit || 0,
    });
  }

  if (isLoading || !isValid) {
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );
  }

  if (!item) {
    return <h1>{t("text.productNotFound")}</h1>;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parseFloat(value) || 0,
    }));
  };

  const handleSave = () => {
    updateGood(formData)
      .unwrap()
      .then(() => {
        navigate("/goods");
      })
      .catch((error: any) => {
        console.error("Error updating the product:", error);
      });
  };

  return (
    <section className="w-full flex-column justify-center">
      <div className="flex w-full max-w-5xl mt-5 mb-5 mx-auto justify-center flex-wrap">
        <div className="flex p-5">
          <Image width={300} src={formData.imageLink} />
        </div>
        <Card className="flex p-5 ml-2">
          <div className="flex flex-col">
            <h2 className="text-2xl">{t("text.productInformation")}</h2>
            <div className="flex flex-col gap-2 mt-5">
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.masterTitle")}:
                </span>
                <span className="w-1/2 text-right">{formData.masterTitle}</span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">{t("text.masterSku")}:</span>
                <span className="w-1/2 text-right">{formData.masterSku}</span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">{t("text.model")}:</span>
                <span className="w-1/2 text-right">{formData.model}</span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.verticalCategory")}:
                </span>
                <span className="w-1/2 text-right">
                  {formData.verticalCategory}
                </span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">{t("text.dateCreate")}:</span>
                <span className="w-1/2 text-right">
                  {formatDate(item.dateCreate)}
                </span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">{t("text.priceNow")}:</span>
                <span className="w-1/2 text-right">
                  {formatCurrency(formData.priceNow)}
                </span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.costOfGoods")}:
                </span>
                <Input
                  className="w-1/2 text-right"
                  placeholder={t("text.costOfGoods")}
                  name="costOfGoods"
                  type="number"
                  value={formData.costOfGoods.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.сommissionRate")}:
                </span>
                <Input
                  className="w-1/2 text-right"
                  placeholder={t("text.сommissionRate")}
                  name="сommissionRate"
                  type="number"
                  value={formData.сommissionRate.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.shippingCost")}:
                </span>
                <Input
                  className="w-1/2 text-right"
                  placeholder={t("text.shippingCost")}
                  name="shippingCost"
                  type="number"
                  value={formData.shippingCost.toString()}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">
                  {t("text.profit")}:
                </span>
                <span className="w-1/2 text-right">
                  {formatCurrency(formData.netProfit)}
                </span>
              </div>
              <div className="flex w-full">
                <span className="w-1/2 text-left">{t("text.netProfit")}:</span>
                <span className="w-1/2 text-right">
                  {formatCurrency(formData.profit)}
                </span>
              </div>
            </div>

            <Button
              color="primary"
              onClick={handleSave}
              className="mt-5 min-w-300"
            >
              {t("button.save")}
            </Button>
            <Button className="mt-5 min-w-300" onClick={() => navigate(-1)}>
              {t("button.cancel")}
            </Button>
          </div>
        </Card>
      </div>
    </section>
  );
};
