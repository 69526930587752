import React, { useState } from "react";
import { useCheckAuthMutation } from "../app/services/authApi";
import { Input, Button, CardBody } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "../components/ErrorMessage";

export const Login = ({ onLoginSuccess }: { onLoginSuccess: () => void }) => {
  const { t } = useTranslation();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [checkAuth, { isLoading }] = useCheckAuthMutation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await checkAuth({ login, password }).unwrap();
      if (response.isValid) {
        localStorage.setItem("accessToken", response.accessToken);
        onLoginSuccess();
      } else {
        setError("Неверный логин или пароль");
      }
    } catch (err: any) {
      if (err?.data?.error) {
        setError(err.data.error);
      } else if (err.message) {
        setError(err.message);
      } else {
        setError("Заполните поля");
      }
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <CardBody className="flex  justify-between">
        <form onSubmit={handleLogin} className="flex flex-col gap-4">
          <Input
            type="email"
            label={t("input.login")}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
            disabled={isLoading}
          />
          <Input
            type="password"
            label={t("input.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
          />
          <div className="flex justify-center">
            <ErrorMessage error={error} />
          </div>
        </form>
        <Button
          type="submit"
          color="primary"
          onClick={handleLogin}
          disabled={isLoading}
        >
          {t("button.login")}
        </Button>
      </CardBody>
    </div>
  );
};
