// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authApi } from "../app/services/authApi";
import { goodsApi } from "./services/goodsApi";
import { totalsInfoApi } from "./services/totalsInfoApi";
import { archiveListApi } from "./services/archivesApi";
import { requestReturnsApi } from "./services/requestReturnsApi";
import { testRequestReturnsApi } from "./services/testRequestReturnsApi";
import { parameterUserApi } from "./services/parameterUserApi";
import authReducer from "../features/authSlice";
import { allDownloadKaspiGoodsApi } from "./services/allDownloadKaspiGoodsApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [goodsApi.reducerPath]: goodsApi.reducer,
    [totalsInfoApi.reducerPath]: totalsInfoApi.reducer,
    [archiveListApi.reducerPath]: archiveListApi.reducer,
    [requestReturnsApi.reducerPath]: requestReturnsApi.reducer,
    [testRequestReturnsApi.reducerPath]: testRequestReturnsApi.reducer,
    [parameterUserApi.reducerPath]: parameterUserApi.reducer,
    [allDownloadKaspiGoodsApi.reducerPath]: allDownloadKaspiGoodsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(goodsApi.middleware)
      .concat(totalsInfoApi.middleware)
      .concat(archiveListApi.middleware)
      .concat(requestReturnsApi.middleware)
      .concat(testRequestReturnsApi.middleware)
      .concat(parameterUserApi.middleware)
      .concat(allDownloadKaspiGoodsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
