import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils"; // Если у вас есть такая функция
import type { IAuthResponse, IAuthKaspiResponse } from "../types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    checkAuth: build.mutation<
      IAuthResponse,
      { login: string; password: string }
    >({
      query: ({ login, password }) => ({
        url: "auth/checkAuth",
        method: "POST",
        body: { login, password },
      }),
    }),
    checkAuthKaspi: build.mutation<
      IAuthKaspiResponse,
      {
        login: string;
        password: string;
        accessToken: string;
        isValidToken: boolean;
      }
    >({
      query: ({ login, password, accessToken, isValidToken }) => ({
        url: "auth/checkAuthKaspi",
        method: "POST",
        body: { login, password, accessToken, isValidToken },
      }),
    }),
  }),
});

export const { useCheckAuthMutation, useCheckAuthKaspiMutation } = authApi;
