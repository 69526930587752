import { Avatar } from "@nextui-org/react";
import logo from "./../assests/images/logo.png";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-full">
      <div className="p-5 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Avatar radius="lg" src={logo} size="lg" />
          {t("text.companyName")}
        </div>
        <div className="text-lg">
          {t("text.phoneNumberText")} {t("text.phoneNumber")}{" "}
        </div>
        <div>
          <div>{t("text.companyNameGroup")}</div>
        </div>
      </div>
    </footer>
  );
};
