import { useState, useMemo } from "react";
import { useTokenValidation } from "../utils/tokenValidation";
import { useNavigate } from "react-router-dom";
import { useGetGoodsQuery } from "./../app/services/goodsApi";
import {
  Button,
  Image,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Input,
  Card,
  Spinner,
  Pagination,
} from "@nextui-org/react";
import { CiEdit } from "react-icons/ci";
import { useTranslation } from "react-i18next";

export const Goods = () => {
  const isValid = useTokenValidation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetGoodsQuery(null);
  const [filters, setFilters] = useState({
    verticalCategory: "",
    masterTitle: "",
    masterSku: "",
    dateCreate: "",
    priceNow: "",
    сommissionRate: "",
    shippingCost: "",
    profit: "",
    netProfit: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "KZT",
    }).format(value);
  };

  const columns = [
    { key: "masterTitle", label: t("text.masterTitle") },
    { key: "model", label: t("text.model") },
    { key: "photo", label: t("text.photo") },
    { key: "masterSku", label: t("text.masterSku") },
    { key: "verticalCategory", label: t("text.verticalCategory") },
    { key: "priceNow", label: t("text.priceNow") },
    { key: "dateCreate", label: t("text.dateCreate") },
    { key: "costOfGoods", label: t("text.costOfGoods") },
    { key: "сommissionRate", label: t("text.сommissionRate") },
    { key: "shippingCost", label: t("text.shippingCost") },
    { key: "profit", label: t("text.profit") },
    { key: "netProfit", label: t("text.netProfit") },
    { key: "edit", label: t("text.edit") },
  ];

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: "masterTitle",
    direction: "asc",
  });

  const items = data?.result || [];

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [items, sortConfig]);

  const filteredItems = useMemo(() => {
    return sortedItems.filter((item: any) => {
      return (
        (!filters.verticalCategory ||
          item.verticalCategory.includes(filters.verticalCategory)) &&
        (!filters.masterTitle ||
          item.masterTitle.includes(filters.masterTitle)) &&
        (!filters.masterSku || item.masterSku.includes(filters.masterSku)) &&
        (!filters.dateCreate ||
          item.dateCreate.toString().includes(filters.dateCreate)) &&
        (!filters.priceNow ||
          item.priceNow.toString().includes(filters.priceNow)) &&
        (!filters.сommissionRate ||
          item.сommissionRate.toString().includes(filters.сommissionRate)) &&
        (!filters.shippingCost ||
          item.shippingCost.toString().includes(filters.shippingCost)) &&
        (!filters.profit ||
          item.netProfit.toString().includes(filters.profit)) &&
        (!filters.netProfit ||
          item.netProfit.toString().includes(filters.netProfit))
      );
    });
  }, [sortedItems, filters]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredItems.slice(startIndex, endIndex);
  }, [filteredItems, currentPage]);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleSort = (key: string) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = (masterSku: string) => {
    navigate(`/main/GetGoodsById/${masterSku}`);
  };

  const handleResetFilters = () => {
    setFilters({
      verticalCategory: "",
      masterTitle: "",
      masterSku: "",
      dateCreate: "",
      priceNow: "",
      сommissionRate: "",
      shippingCost: "",
      profit: "",
      netProfit: "",
    });
    setCurrentPage(1);
  };

  if (isLoading) {
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );
  }

  if (!isValid) {
    return <Spinner />;
  }

  return (
    <section className="w-full px-5">
      <Card className="gap-5 mt-5 p-5">
        <p className="text-2xl">{t("page.goods")}</p>
      </Card>
      <Card className="filters grid grid-cols-4 gap-5 mt-5 p-5">
        <Input
          label={t("text.masterTitle")}
          name="masterTitle"
          value={filters.masterTitle}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.masterSku")}
          name="masterSku"
          value={filters.masterSku}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.priceNow")}
          name="priceNow"
          type="number"
          value={filters.priceNow}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.shippingCost")}
          name="shippingCost"
          value={filters.shippingCost}
          onChange={handleFilterChange}
        />
      </Card>
      <Card className="flex justify-end gap-5 mt-5 p-5">
        <Button
          color="primary"
          onClick={handleResetFilters}
          className="max-w-[284px]"
        >
          {t("button.resetFilters")}
        </Button>
      </Card>

      <Table className="mt-5">
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn
              minWidth={100}
              whitespace-pre-wrap
              key={column.key}
              onClick={() => handleSort(column.key)}
              style={{ cursor: "pointer" }}
            >
              {column.label}{" "}
              {sortConfig.key === column.key
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody>
          {paginatedItems.map((item: any) => (
            <TableRow key={item.id}>
              <TableCell style={{ minWidth: "200px" }}>
                {item.masterTitle}
              </TableCell>
              <TableCell style={{ minWidth: "200px" }}>{item.model}</TableCell>
              <TableCell>
                <Image width={60} height={50} src={item.imageLink} />
              </TableCell>
              <TableCell>{item.masterSku}</TableCell>
              <TableCell>{item.verticalCategory}</TableCell>
              <TableCell className="font-semibold">
                {formatCurrency(item.priceNow)}
              </TableCell>
              <TableCell>{formatDate(item.dateCreate)}</TableCell>
              <TableCell className="font-semibold">
                {formatCurrency(item.costOfGoods)}
              </TableCell>
              <TableCell className="font-semibold">
                {item.сommissionRate !== null &&
                item.сommissionRate !== undefined ? (
                  <div>{item.сommissionRate}%</div>
                ) : (
                  <div>0%</div>
                )}
              </TableCell>
              <TableCell className="font-semibold">
                {formatCurrency(item.shippingCost)}
              </TableCell>
              <TableCell className="font-semibold">
                {formatCurrency(item.profit)}
              </TableCell>
              <TableCell className="font-semibold">
                {formatCurrency(item.netProfit)}
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => handleEdit(item.masterSku)}
                  style={{ margin: 0, padding: 0, minWidth: "40px" }}
                >
                  <CiEdit className="text-2xl" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Card className="flex items-center gap-5 mt-5 p-5">
        <Pagination
          total={totalPages}
          initialPage={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </Card>
    </section>
  );
};
