import { useEffect } from "react";
import { useLazyGetAllDownloadKaspiGoodsApiQuery } from "./../app/services/allDownloadKaspiGoodsApi";

export const AutomaticKaspiGoodsUpdater = () => {
  const [trigger] = useLazyGetAllDownloadKaspiGoodsApiQuery();

  useEffect(() => {
    const intervalId = setInterval(() => {
      trigger(null);
    }, 600000);

    return () => clearInterval(intervalId);
  }, [trigger]);

  return null;
};
