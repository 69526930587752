import React, { useState } from "react";
import { useCheckAuthKaspiMutation } from "../app/services/authApi";
import { Input, Button, CardBody, Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";

export const LoginKaspi = () => {
  const { t } = useTranslation();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isValidToken] = useState(true);
  const [error, setError] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const navigate = useNavigate();

  const [checkAuthKaspi, { isLoading }] = useCheckAuthKaspiMutation();

  const handleKaspiLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setError("Access token is missing");
      return;
    }

    setIsSpinnerVisible(true);

    try {
      const response = await checkAuthKaspi({
        login,
        password,
        accessToken,
        isValidToken,
      }).unwrap();

      if (response.isValidToken) {
        navigate("/main");
      }
    } catch (error) {
      setError("Неверный логин или пароль");
    } finally {
      setIsSpinnerVisible(false);
    }
  };

  return (
    <div className="relative flex flex-col gap-2">
      {isSpinnerVisible && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <Spinner />
        </div>
      )}
      <CardBody className="flex  justify-between">
        <form onSubmit={handleKaspiLogin} className="flex flex-col gap-4">
          <Input
            type="email"
            label={t("input.loginKaspi")}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
            disabled={isLoading || isSpinnerVisible}
          />
          <Input
            type="password"
            label={t("input.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading || isSpinnerVisible}
          />
          <div className="flex justify-center">
            <ErrorMessage error={error} />
          </div>
        </form>
        <Button
          type="submit"
          color="primary"
          onClick={handleKaspiLogin}
          disabled={isLoading || isSpinnerVisible}
        >
          {t("button.login")}
        </Button>
      </CardBody>
    </div>
  );
};
