import { Nav } from "./Nav";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Input,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { CiLogout } from "react-icons/ci";
import logo from "./../assests/images/logo.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IoSettingsOutline } from "react-icons/io5";
import { logout } from "../features/authSlice";
import {
  useGetParameterUserQuery,
  useGetUpdateParametersUserMutation,
} from "./../app/services/parameterUserApi";
import { KaspiTokenData } from "./KaspiTokenData";
import { ChangeTheme } from "./ChangeTheme";
// import LanguageSelector from "./LanguageSelector";
import { UpdatesKaspiAllGoods } from "./UpdatesKaspiAllGoods";
import { AutomaticKaspiGoodsUpdater } from "./AutomaticKaspiGoodsUpdater";

export const Header = () => {
  const { t } = useTranslation();

  const {
    data: parameterData,
    isLoading,
    refetch,
  } = useGetParameterUserQuery(null);
  const [updateParameterUser] = useGetUpdateParametersUserMutation();

  const [kaspiToken, setKaspiToken] = useState("");
  const [minutesUpdate, setMinutesUpdate] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (parameterData?.result) {
      setKaspiToken(parameterData.result.kaspiToken || "");
      setMinutesUpdate(parameterData.result.minutesUpdate.toString() || "");
    }
  }, [parameterData]);

  const handleKaspiTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKaspiToken(e.target.value);
  };

  const handleMinutesUpdateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value, 10);

    if (value >= 1 && value <= 120) {
      setMinutesUpdate(value.toString());
    } else if (value < 1) {
      setMinutesUpdate("1");
    } else if (value > 120) {
      setMinutesUpdate("120");
    }
  };

  const handleUpdate = () => {
    updateParameterUser({ kaspiToken, minutesUpdate: parseInt(minutesUpdate) })
      .unwrap()
      .then(() => {
        refetch();
        setIsPopoverOpen(false);
      })
      .catch((error) => {
        console.error("Ошибка при обновлении:", error);
      });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    navigate("/");
  };

  if (isLoading) {
    return <p></p>;
  }

  return (
    <header className="flex flex-col">
      <Navbar maxWidth="full" className="pt-5 pb-5">
        <NavbarBrand className="gap-2">
          <Avatar src={logo} size="lg" />
          <p className="text-inherit text-lg">{t("text.companyName")}</p>
        </NavbarBrand>
        <NavbarContent>
          <NavbarItem className="text-white flex items-center gap-2 justify-end w-full">
            <ChangeTheme />
            <Popover
              placement="bottom-end"
              isOpen={isPopoverOpen}
              onOpenChange={(open) => setIsPopoverOpen(open)}
            >
              <PopoverTrigger>
                <Button isIconOnly color="primary" className="flex gap-5">
                  <IoSettingsOutline size={"24px"} />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col gap-5">
                  <Input
                    type="text"
                    label={t("input.kaspiToken")}
                    value={kaspiToken}
                    onChange={handleKaspiTokenChange}
                  />
                  <Input
                    min={1}
                    max={120}
                    type="number"
                    label={t("input.time")}
                    value={minutesUpdate}
                    onChange={handleMinutesUpdateChange}
                  />
                  <Button onClick={handleUpdate} color="primary">
                    {t("button.save")}
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
            <AutomaticKaspiGoodsUpdater />
            <UpdatesKaspiAllGoods />
            {/* <LanguageSelector /> */}
            <Card>
              <CardBody className="flex flex-row gap-x-2 items-center">
                <KaspiTokenData />
                <Button
                  color="default"
                  variant="flat"
                  className="gap-2 w-24"
                  onClick={handleLogout}
                >
                  <CiLogout /> {t("button.logout")}
                </Button>
              </CardBody>
            </Card>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      <Navbar maxWidth="full">
        <NavbarContent>
          <Nav />
        </NavbarContent>
      </Navbar>
    </header>
  );
};
