import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import type { ITotalsInfoResponse } from "./../types";
import { getTokenFromLocalStorage } from "./../../utils/token-utils";

export const totalsInfoApi = createApi({
  reducerPath: "totalsInfoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getTotalsInfo: build.query<ITotalsInfoResponse, string>({
      query: (date) => `main/getMainData/${date}`,
    }),
  }),
});

export const { useGetTotalsInfoQuery } = totalsInfoApi;
