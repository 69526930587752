import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils";

export const goodsApi = createApi({
  reducerPath: "goodsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Goods"],
  endpoints: (build) => ({
    getGoods: build.query({
      query: () => `main/getGoods`,
      providesTags: ["Goods"],
    }),
    getGoodById: build.query({
      query: (getGoodById) => ({
        url: `main/GetGoodsById/${getGoodById.masterSku}`,
        method: "GET",
      }),
      providesTags: ["Goods"],
    }),
    updateGood: build.mutation({
      query: (updatedGood) => ({
        url: `main/changeGoodsKaspi`,
        method: "POST",
        body: updatedGood,
      }),
      invalidatesTags: ["Goods"],
    }),
    getStatisticForDiagram: build.query({
      query: (dateRange) => ({
        url: `main/getStatisticForDiagram?date=${dateRange}`,
        method: "GET"
      })
    })
  }),
});

export const { useGetGoodsQuery, useUpdateGoodMutation, useGetGoodByIdQuery, useGetStatisticForDiagramQuery } = goodsApi;
