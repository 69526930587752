import { useState } from "react";
import { useTokenValidation } from "../utils/tokenValidation";
import { useGetStatisticForDiagramQuery } from "./../app/services/goodsApi";
import { useGetTotalsInfoQuery } from "./../app/services/totalsInfoApi";
import {
  Card,
  CardBody,
  Select,
  SelectItem,
  Spinner,
  DatePicker,
} from "@nextui-org/react";
import { parseDate } from "@internationalized/date";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// icons from react-icons
import { MdOutlineMonetizationOn } from "react-icons/md";
import { FcSalesPerformance } from "react-icons/fc";
import { HiMiniReceiptRefund } from "react-icons/hi2";
import { TbReceiptTax } from "react-icons/tb";
import { FaReceipt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const Main = () => {
  const { t } = useTranslation();
  const isValid = useTokenValidation();

  const formatDate = (date: any) => {
    const day = String(date.day).padStart(2, "0");
    const month = String(date.month).padStart(2, "0");
    const year = date.year;
    return `${month}.${day}.${year}`;
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "KZT",
    }).format(value);
  };

  const [selectedDate, setSelectedDate] = useState(
    parseDate(new Date().toISOString().split("T")[0])
  );

  const [dateRange, setDateRange] = useState(0);
  const formattedDate = formatDate(selectedDate);

  const { data: totalsData, isLoading: isLoadingTotals } =
    useGetTotalsInfoQuery(formattedDate);
  const totals = totalsData?.result || null;

  const { data: diagramData, isLoading: isLoadingDiagram } =
    useGetStatisticForDiagramQuery(dateRange);
  const items = diagramData?.result || [];

  if (isLoadingTotals || isLoadingDiagram)
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );

  if (!isValid) {
    <Spinner className="flex justify-center items-center min-w-full min-h-screen" />;
  }

  return (
    <section className="w-full px-5">
      <Card className="gap-5 mt-5 p-5">
        <p className="text-2xl">{t("page.main")}</p>
      </Card>
      <Card className="my-5 p-5">
        <DatePicker
          className="max-w-[284px]"
          label={t("text.dateText")}
          value={selectedDate}
          onChange={setSelectedDate}
          maxValue={parseDate(new Date().toISOString().split("T")[0])}
        />
      </Card>
      {totals ? (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 mt-5">
          <Card className="p-5 flex-row items-center flex-1">
            <FcSalesPerformance size={60} />
            <CardBody>
              <div className="flex flex-col">
                <p className="text-small text-default-500">{t("text.sales")}</p>
                <div>{formatCurrency(totals.salesAmount)}</div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-5 flex-row items-center flex-1">
            <TbReceiptTax size={60} />
            <CardBody>
              <div className="flex flex-col">
                <p className="text-small text-default-500">
                  {t("text.commission")}
                </p>
                <div>{formatCurrency(totals.amountPercentageСommission)}</div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-5 flex-row items-center flex-1">
            <MdOutlineMonetizationOn size={65} color="gold" />
            <CardBody>
              <div className="flex flex-col">
                <p className="text-small text-default-500">
                  {t("text.netProfit")}
                </p>
                <div>{formatCurrency(totals.amountOfNetIncome)}</div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-5 flex-row items-center flex-1">
            <FaReceipt size={45} />
            <CardBody>
              <div className="flex flex-col">
                <p className="text-small text-default-500">
                  {t("text.averageCheck")}
                </p>
                <div>{formatCurrency(totals.averageReceipt)}</div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-5 flex-row items-center flex-1">
            <HiMiniReceiptRefund size={70} />
            <CardBody>
              <div className="flex flex-col">
                <p className="text-small text-default-500">
                  {t("text.returns")}
                </p>
                <div>{formatCurrency(totals.amountOfRefunds)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
      ) : (
        <div>Данные не прогрузились</div>
      )}
      <Card className="my-5 p-5">
        <Select
          label={t("select.selectPeriod")}
          className="max-w-[284px]"
          value={0}
          onChange={(event) => setDateRange(Number(event.target.value))}
        >
          <SelectItem key={0} value={0}>
            {t("text.week")}
          </SelectItem>
          <SelectItem key={1} value={1}>
            {t("text.month")}
          </SelectItem>
        </Select>
      </Card>
      <Card className="my-5 py-5">
        <ResponsiveContainer className="w-full" height={350}>
          <BarChart data={items} width={100} height={350} syncId="anyId">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis dataKey="count" />
            <Tooltip />
            <Bar
              dataKey="date"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Bar
              dataKey="count"
              fill="#82ca9d"
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </section>
  );
};
