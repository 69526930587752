import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./../../constants";
import { getTokenFromLocalStorage } from "../../utils/token-utils";

export const parameterUserApi = createApi({
  reducerPath: "parameterUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ParameterUser"],
  endpoints: (build) => ({
    getParameterUser: build.query({
      query: () => ({
        url: `main/getParametersUser`,
        method: "GET",
      }),
      providesTags: ["ParameterUser"],
    }),
    getUpdateParametersUser: build.mutation({
      query: (getUpdateParametersUser) => ({
        url: `main/updateParametersUser`,
        method: "POST",
        body: getUpdateParametersUser,
      }),
    }),
  }),
});

export const { useGetParameterUserQuery, useGetUpdateParametersUserMutation } = parameterUserApi;
