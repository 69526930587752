import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Main } from "../pages/Main";
import { Goods } from "../pages/Goods";
import ProtectedRoute from "./ProtectedRoute";
import Layout from "../components/Layout";
import { Auth } from "../pages/Auth";
import { History } from "../pages/History";
import { RequestsReturns } from "../pages/RequestsReturns";
import { CurrentGoods } from "../pages/CurrentGoods";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route
          element={
            <Layout>
              <ProtectedRoute />
            </Layout>
          }
        >
          <Route path="/main" element={<Main />} />
          <Route path="/goods" element={<Goods />} />
          <Route
            path="/main/GetGoodsById/:masterSku"
            element={<CurrentGoods />}
          />
          <Route path="/history" element={<History />} />
          <Route path="/requestsReturns" element={<RequestsReturns />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
