import { useState, useMemo } from "react";
import { useGetArchiveListQuery } from "./../app/services/archivesApi";
import {
  Button,
  Card,
  Chip,
  Input,
  Select,
  SelectItem,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Pagination,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useTokenValidation } from "../utils/tokenValidation";

export const History = () => {
  const isValid = useTokenValidation();
  const { t } = useTranslation();
  const { data, isLoading } = useGetArchiveListQuery(null);

  const [filters, setFilters] = useState({
    masterSku: "",
    orderCode: "",
    name: "",
    category: "",
    createOrderDateMin: "",
    createOrderDateMax: "",
    modifiedOrderDateMin: "",
    modifiedOrderDateMax: "",
    totalPriceMin: "",
    totalPriceMax: "",
    orderStatus: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const resetFilters = () => {
    setFilters({
      masterSku: "",
      orderCode: "",
      name: "",
      category: "",
      createOrderDateMin: "",
      createOrderDateMax: "",
      modifiedOrderDateMin: "",
      modifiedOrderDateMax: "",
      totalPriceMin: "",
      totalPriceMax: "",
      orderStatus: "",
    });
    setCurrentPage(1);
  };

  const columns = [
    { key: "masterSku", label: t("text.masterSku") },
    { key: "orderCode", label: t("text.orderCode") },
    { key: "createOrderDate", label: t("text.createOrderDate") },
    { key: "modifiedOrderDate", label: t("text.modifiedDate") },
    { key: "name", label: t("text.masterTitle") },
    { key: "category", label: t("text.verticalCategory") },
    { key: "totalPrice", label: t("text.priceNow") },
    { key: "customer", label: t("text.customer") },
    { key: "pickupPoint", label: t("text.pickupPoint") },
    { key: "orderStatus", label: t("text.orderStatus") },
  ];

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "KZT",
    }).format(value);
  };

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: "createOrderDate",
    direction: "asc",
  });

  const [archivedData, setArchivedData] = useState<any[]>([]);

  useMemo(() => {
    if (data) {
      setArchivedData(data.result);
    }
  }, [data]);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleSort = (key: string) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const filteredItems = useMemo(() => {
    const sortedItems = [...archivedData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sortedItems.filter((item: any) => {
      const itemCreateDate = new Date(item.createOrderDate).setHours(
        0,
        0,
        0,
        0
      );
      const itemModifiedDate = new Date(item.modifiedOrderDate).setHours(
        0,
        0,
        0,
        0
      );

      const createMinDate = filters.createOrderDateMin
        ? new Date(filters.createOrderDateMin).setHours(0, 0, 0, 0)
        : -Infinity;
      const createMaxDate = filters.createOrderDateMax
        ? new Date(filters.createOrderDateMax).setHours(23, 59, 59, 999)
        : Infinity;

      const modifiedMinDate = filters.modifiedOrderDateMin
        ? new Date(filters.modifiedOrderDateMin).setHours(0, 0, 0, 0)
        : -Infinity;
      const modifiedMaxDate = filters.modifiedOrderDateMax
        ? new Date(filters.modifiedOrderDateMax).setHours(23, 59, 59, 999)
        : Infinity;

      const minPrice = filters.totalPriceMin
        ? parseFloat(filters.totalPriceMin)
        : -Infinity;
      const maxPrice = filters.totalPriceMax
        ? parseFloat(filters.totalPriceMax)
        : Infinity;

      return (
        (!filters.masterSku || item.masterSku.includes(filters.masterSku)) &&
        (!filters.orderCode || item.orderCode.includes(filters.orderCode)) &&
        (!filters.name || item.name.includes(filters.name)) &&
        (!filters.category || item.category.includes(filters.category)) &&
        itemCreateDate >= createMinDate &&
        itemCreateDate <= createMaxDate &&
        itemModifiedDate >= modifiedMinDate &&
        itemModifiedDate <= modifiedMaxDate &&
        item.totalPrice >= minPrice &&
        item.totalPrice <= maxPrice &&
        (!filters.orderStatus || item.orderStatus === filters.orderStatus)
      );
    });
  }, [archivedData, filters, sortConfig]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredItems.slice(startIndex, endIndex);
  }, [filteredItems, currentPage]);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  if (isLoading)
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );

  if (!isValid) {
    return (
      <Spinner className="flex justify-center items-center min-w-full min-h-screen" />
    );
  }

  return (
    <section className="w-full px-5 h-full">
      <Card className="gap-5 mt-5 p-5">
        <p className="text-2xl">{t("page.history")}</p>
      </Card>
      <Card className="filters grid grid-cols-4 gap-5 mt-5 p-5">
        <Input
          label={t("text.masterSku")}
          name="masterSku"
          value={filters.masterSku}
          onChange={handleFilterChange}
        />
        <Input
          type="number"
          label={t("text.orderCode")}
          name="orderCode"
          value={filters.orderCode}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.dateCreateFrom")}
          name="createOrderDateMin"
          type="date"
          value={filters.createOrderDateMin}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.dateCreateTo")}
          name="createOrderDateMax"
          type="date"
          value={filters.createOrderDateMax}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.modifiedDateFrom")}
          name="modifiedOrderDateMin"
          type="date"
          value={filters.modifiedOrderDateMin}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.modifiedDateTo")}
          name="modifiedOrderDateMax"
          type="date"
          value={filters.modifiedOrderDateMax}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.priceMin")}
          name="totalPriceMin"
          type="number"
          value={filters.totalPriceMin}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.priceMax")}
          name="totalPriceMax"
          type="number"
          value={filters.totalPriceMax}
          onChange={handleFilterChange}
        />
        <Input
          label={t("text.verticalCategory")}
          name="category"
          value={filters.category}
          onChange={handleFilterChange}
        />
        <Select
          label={t("text.orderStatus")}
          name="orderStatus"
          value={filters.orderStatus}
          onChange={handleFilterChange}
        >
          <SelectItem key="" value="">
            {t("text.allParameters")}
          </SelectItem>
          <SelectItem key="ВЫДАН" value="ВЫДАН">
            ВЫДАН
          </SelectItem>
          <SelectItem key="ОТМЕНЕН" value="ОТМЕНЕН">
            ОТМЕНЕН
          </SelectItem>
        </Select>
      </Card>

      <Card className="flex justify-end gap-5 mt-5 p-5">
        <Button
          onClick={resetFilters}
          color="primary"
          className="max-w-[284px]"
        >
          {t("button.reset")}
        </Button>
      </Card>
      <Table className="mt-5">
        <TableHeader columns={columns} className="grid grid-cols-6 gap-5 mt-5">
          {(column) => (
            <TableColumn
              minWidth={100}
              key={column.key}
              whitespace-pre-wrap
              onClick={() => handleSort(column.key)}
              style={{ cursor: "pointer" }}
            >
              {column.label}{" "}
              {sortConfig.key === column.key
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody className="grid grid-cols-6 gap-5 mt-5">
          {paginatedItems.map((item: any) => (
            <TableRow key={item.id}>
              <TableCell>{item.masterSku}</TableCell>
              <TableCell>{item.orderCode}</TableCell>
              <TableCell>{formatDate(item.createOrderDate)}</TableCell>
              <TableCell>{formatDate(item.modifiedOrderDate)}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{formatCurrency(item.totalPrice)}</TableCell>
              <TableCell>{item.customer}</TableCell>
              <TableCell>{item.pickupPoint}</TableCell>
              <TableCell>
                <Chip
                  color={item.orderStatus === "ВЫДАН" ? "success" : "danger"}
                >
                  {item.orderStatus}
                </Chip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Card className="flex items-center gap-5 mt-5 p-5">
        <Pagination
          total={totalPages}
          initialPage={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </Card>
    </section>
  );
};
