import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <Header />
        <div className="h-full">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
