import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
} from "@nextui-org/react";
import { useLazyGetAllDownloadKaspiGoodsApiQuery } from "./../app/services/allDownloadKaspiGoodsApi";

export const UpdatesKaspiAllGoods = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [trigger, { isLoading, error }] =
    useLazyGetAllDownloadKaspiGoodsApiQuery();

  const handleUpdateClick = () => {
    onOpen();
    trigger(null);
  };

  const handleClose = () => {
    onOpenChange();
  };

  return (
    <div>
      <Button color="primary" onPress={handleUpdateClick}>
        Обновить данные
      </Button>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <p id="modal-title" className="text-lg">
                  {isLoading
                    ? "Данные обновляются! Подождите пару минут!"
                    : error
                    ? "Ошибка при обновлении данных"
                    : "Данные успешно обновлены!"}
                </p>
              </ModalHeader>
              <ModalBody>
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Spinner />
                  </div>
                ) : (
                  <p className="text-lg">
                    {error
                      ? "Не удалось обновить данные."
                      : "Данные успешно обновлены!"}
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                {!isLoading && (
                  <Button
                    color="primary"
                    onPress={() => {
                      handleClose();
                      onClose();
                    }}
                  >
                    Ок
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
