import {
  Avatar,
  Card,
  CardBody,
  Image,
  Navbar,
  NavbarContent,
  NavbarItem,
  Textarea,
} from "@nextui-org/react";
import { Login } from "../components/Login";
import { useState } from "react";
import logo from "./../assests/images/logo.png";
import mainPic from "./../assests/images/mainIcon.avif";
import { Footer } from "./../components/Footer";
import { LoginKaspi } from "./../components/LoginKaspi";
import { useTranslation } from "react-i18next";
import { ChangeTheme } from "../components/ChangeTheme";
// import LanguageSelector from "../components/LanguageSelector";

export const Auth = () => {
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  return (
    <div
      className="h-screen flex flex-col justify-between"
      style={{ maxWidth: "2560px" }}
    >
      <Navbar maxWidth="full" className="pt-5 pb-5">
        <NavbarItem
          className="flex items-center"
          style={{ gap: "10px", fontSize: "24px" }}
        >
          <Avatar radius="lg" src={logo} size="lg" />
          {t("text.companyName")}
        </NavbarItem>
        <NavbarContent justify="end">
          <ChangeTheme />
          {/* <LanguageSelector /> */}
        </NavbarContent>
      </Navbar>
      <div className="flex flex-col gap-[10px] items-center">
        <div className="auth__content">
          <Image width={320} src={mainPic} />
        </div>
        <div className="auth__content__title">{t("text.companyName")}</div>
        <Textarea
          isDisabled
          labelPlacement="outside"
          value={t("text.authDescription")}
          className="max-w-[320px] h-[30px] text-center mb-2"
        />
        <Card className="max-w-full w-[320px] min-h-17">
          <CardBody className="overflow-hidden">
            {!isLoggedIn ? (
              <Login onLoginSuccess={handleLoginSuccess} />
            ) : (
              <LoginKaspi />
            )}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </div>
  );
};
